<template>
  <div class="page-container">
    <van-skeleton title :row="20" :loading="pageLoading">
      <div class="page-title" v-if="pageTitle">
        <p>{{pageTitle}}</p>
      </div>

      <div class="page-content" v-html="pageContent">
      </div>

      <sdut-banner />
    </van-skeleton>
  </div>
</template>

<script>
import SdutBanner from '@/components/SdutBanner'
import { getPageData } from '@/api/other'

export default {
  components: { SdutBanner },
  data () {
    return {
      pageKey: null,
      pageContent: null,
      pageLoading: true,
      pageTitle: null
    }
  },
  mounted () {
    this.pageKey = this.$route.params.page
    this.fetchData()
  },
  methods: {
    fetchData () {
      getPageData(this.pageKey).then(response => {
        this.pageContent = response.content
        this.pageTitle = response.title
        document.title = response.title
        this.pageLoading = false
      })
    }
  }
}
</script>

<style>
.page-content {
  background-color: #ffffff;
  padding: 1rem 1rem;
}

.page-status {
  background-color: #ffffff;
}

.page-title {
  background-color: #ffffff;
}

.page-title p {
  margin: 0;
  text-align: center;
  font-size: 1.5rem;
  line-height: 3rem;
  font-weight: bold;
}

</style>
